import { axiosKtb } from "@/plugins/axios";
import {Save,Obtener} from '@/Generico/Conexion'
export class TurnoRequest
{
    plantaId?: number;
    descripcion?: string;
    fechaInicio?: string;
    fechaFin?: string;
    horaInicio?: string;
    horaFin?: string;

    constructor(_plantaId?:number,_descripcion?:string,_fechaInicio?:string,
                _fechaFin?:string,_horaInicio?:string,_horaFin?:string)
                {
                    this.plantaId = _plantaId;
                    this.descripcion = _descripcion;
                    this.fechaInicio  = _fechaInicio;
                    this.fechaFin = _fechaFin;
                    this.horaInicio = _horaInicio;
                    this.horaFin = _horaFin
                }
}

export class TurnoQuery
{
    turnoId!: number;
    plantaName!:string;
    plantaId!: number;
    descripcion!: string;
    fechaInicio!: string;
    fechaFin!: string;
    horaInicio!: string;
    horaFin!: string;
    estado!:boolean;


}

export async function RegistrarTurno(turno:TurnoRequest)
{
    return await Save<TurnoRequest>('turno/guardar',turno,axiosKtb);
}

export async function ObtenerTurno()
{
    return await Obtener<TurnoQuery>('turno/obtener',axiosKtb);
}

export async function ObtenerTurnoPlanta(plantaId:number)
{
    return await Obtener<TurnoQuery>(`turno/obtenerturnoplanta/${plantaId}`,axiosKtb);
}
