

































import {Vue,Component} from 'vue-property-decorator'
import {TurnoQuery,ObtenerTurno} from '@/entidades/KTB/RegistroInformacion/Turno';
import {Action,State} from 'vuex-class';
import Loading from '@/entidades/Sistema/Loading';
import Alerta from '@/entidades/Sistema/Alerta';
@Component({
    name : 'TurnoView',
    components :{
        'tabla' : () => import('@/components/General/TablaGeneralFinal.vue'),
        'turnoAdd' : ()=> import('@/components/KTB/Operacion/TurnoAdd.vue')
    }
})
export default class TurnoView extends Vue
{
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;

     header:{text:string,sortable:boolean,value:string}[] =
    [
            {text:"Planta",sortable:false,value:'plantaName'},
            {text:"Descripcion",sortable:false,value:'descripcion'},
            {text:"Fech. Inicio",sortable:true,value:'fechaInicio'},
            {text:"Fech. Fin",sortable:false,value:'fechaFin'},
            {text:"Hora Inicio",sortable:true,value:'horaInicio'},
            {text:"Hora Fin",sortable:true,value:'horaFin'},

            {text:"Estado",sortable:false,value:'estado'}
    ];

    listado:Array<TurnoQuery> =[];
    modal:boolean=false;
    componenteName:string ="";
    abrilModal()
    {
        this.modal= true;
        this.componenteName = "turnoAdd";
    }
    limpiar()
    {
        this.modal= false;
        this.componenteName = "";
        this.ObtenerTurno();
    }

    async ObtenerTurno()
    {
        try
        {
            this.changeLoading(new Loading(true,"Cargando informacion"))
            let rsp = await ObtenerTurno();
            if(rsp.isSuccess == true)
            {
              var filtro = rsp.isResult.filter(t=>t.estado)
              console.log(filtro);
                this.listado = filtro;
            }
            else
            {
                this.changeAlerta(new  Alerta(rsp.isMessage,true,"danger",3000));
            }
        }
        catch(error)
        {

        }
        finally
        {
             this.changeLoading(new Loading(false,""))
        }
    }


    mounted() {
        this.ObtenerTurno();
    }
}
